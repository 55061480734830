<template>
    <div class="MultipleLonghuLake flex">
        <div v-for="(item,index) in ImgArray" :key="index" @click="BetClick(index)" class="relative pointer allh flex1">
            <img src="../../../public/img/Multiple/LonghuLake/z.png" class="absolute allw allh">
            <img src="../../../public/img/Multiple/LonghuLake/z_hover.png" class="absolute allw allh">
            <img :src="'./img/Multiple/LonghuLake/'+item + (LangeuageAgent === 'CH' ? '' : LangeuageAgent) +'.png'"
                 class="absolute">
            <span class="absolute font14">{{Proportion[index]}}</span>
            <div class="ChipEffect absolute">
                <!--                top: -.5rem-->
                <span v-show="BetMoney[BetIndex[index]].url.length !== 0"
                      :style="{'marginTop': -4.5*BetMoney[BetIndex[index]].url.length*whdef+'px'}"
                      class="absolute font18">{{BetMoney[BetIndex[index]].money}}</span>
                <img v-for="(l,i) in BetMoney[BetIndex[index]].url" :key="i+l" :src="'./img/Common/xz'+l+'.png'"
                     :style="{'marginBottom': i*4*whdef+'px'}" class="absolute allw">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'MultipleBaccarat',
        props: {
            'TableInfo': {
                default() {
                    return {}
                }, type: Object
            },
            'Count': [Number],
            'GameId': [Number],
            'SetBetState': [Function],
            'CloseBetState': [Function]
        },
        computed: {
            ...mapState(['whdef', 'UserInfo', 'SocketFn', 'DefaultConfig', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CalllConfirmBetFn', 'CalllRevokeBetFn'],
                ImgArray: ['l', 'he', 'h'],
                BetIndex: [0, 2, 1],    //下注顺序 龙 虎 和
                Proportion: ['1:0.97', '1:8', '1:0.97'],
                //下注金额
                BetMoney: [{money: 0, url: [],}, {money: 0, url: [],}, {money: 0, url: [],}],
                //真实下注金额
                CopyBetMoney: [],
                CopyBetMoney1: [],
            }
        },
        created() {
            this.$nextTick(() => {
                if (this.DefaultConfig.LoginTemplate === 'szwjt') this.Proportion.splice(1, 1, '1:30');
                this.CopyBetMoney = this.Clone(this.BetMoney);
                this.CopyBetMoney1 = this.Clone(this.BetMoney);
                this.Init(this.TableInfo);
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    this.Init(n);
                }, deep: true
            },
        },
        methods: {
            Init(n) {
                if (n.hasOwnProperty('betInfo') && n.betInfo !== null) {  //初始化下注信息
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = n.betInfo[index];
                        item.url = this.CountUrl(item.money);
                    });
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                }
            },
            BetClick(index) {  //下注效果
                if (this.TableInfo.gameStatus !== 1) return;  //不在投注状态
                let path = this.BetMoney[this.BetIndex[index]];
                path.money += Number(sessionStorage.getItem(('MultipleCurrentChipOn' + (this.Count + 1))));
                path.url = this.CountUrl(path.money);
            },
            ConfirmBet() { //确认下注   下注顺序为龙 虎 和
                // let gameId = this.$route.query.id;
                let info = this.TableInfo;
                let allBet = 0;
                let betInfo = [0, 0, 0];
                let msg = '';
                if (this.TableInfo.gameStatus !== 1) return; //不在投注状态
                this.BetMoney.forEach((item, index) => {
                    let disparity = item.money - this.CopyBetMoney[index].money;
                    // if (disparity < Number(info.limitRed.split('-')[0]) && disparity !== 0) msg = '投注金额小于最低限红';
                    allBet += disparity;
                    betInfo[index] = disparity;
                });
                if (allBet < Number(info.limitRed.split('-')[0])) msg = '投注金额小于最低限红';
                if (allBet > Number(this.UserInfo.balance)) {
                    this.Toast('2', this.Lang['YEBZ']);//'余额不足'
                    return;
                }
                if (allBet === 0) {
                    this.Toast('2', this.Lang['XYDYL']);//'下注金额必须大于0'
                    return;
                }
                if (msg === '投注金额小于最低限红') {
                    this.SetBetState(1);
                    this.CloseBetState();
                    return;
                }
                sessionStorage.MultipleID = this.GameId;    //缓存多台ID
                this.SocketApi.SendSock([this.GameId, this.cmd.PlayerBet, 0, {
                    gameId: this.GameId,
                    betInfo
                }], 'CalllConfirmBetFn');
            },
            CalllConfirmBetFn(res) {  //下注成功回调  需要保存真实下注金额
                if (res.status === 1 && Number(sessionStorage.MultipleID) === this.GameId) { //成功的时候
                    sessionStorage.removeItem('MultipleID');    //清除多台ID
                    this.CopyBetMoney = this.Clone(this.BetMoney);
                    this.SetBetState(0);
                    this.CloseBetState();
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                }
            },
            RevokeBet() {  //撤销下注
                if (!this.IsPermission()) return;
                // let gameId = this.$route.query.id;
                if (this.TableInfo.gameStatus !== 1) {
                    this.Toast('4', this.Lang['BZXZSJ']);//'不在下注时间，不能撤销下注'
                    return;
                }
                if (this.CopyBetMoney.filter(item => item.money > 0).length === 0) {
                    this.Toast('4', this.Lang['HWXZ']);//'当前未下注，不能撤销下注'
                    return;
                }
                this.SocketApi.SendSock([this.GameId, this.cmd.CancelBet, 0, {
                    gameId: this.GameId
                }], 'CalllRevokeBetFn');
            },
            CalllRevokeBetFn(res) { //撤销下注成功回调
                if (res.status === 1) { //成功的时候
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = res.betInfo[index];
                        item.url = this.CountUrl(item.money);
                    });
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.Toast('1', this.Lang['CXCG']);//'撤销成功'
                    //     this.SetAudio('撤销下注特效');
                }
            },
            CancelBet() { //取消下注
                this.BetMoney = this.Clone(this.CopyBetMoney);
            },
            ClearTable() { //清空桌面
                this.BetMoney = this.Clone(this.CopyBetMoney1);
                this.CopyBetMoney = this.Clone(this.CopyBetMoney1);
            }
        },
    }
</script>

<style lang="less">
    .MultipleLonghuLake {
        height: 1.9rem;

        > div {
            > img:nth-child(2) {
                display: none;
            }

            &:hover {
                > img:nth-child(2) {
                    display: block;
                }
            }

            > img:nth-child(3) {
                height: .28rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            > span {
                color: #6af076;
                left: 50%;
                bottom: .5rem;
                transform: translate(-50%, 0);
            }

            .ChipEffect {
                width: .5rem;
                height: .3rem;
                bottom: .2rem;
                left: .25rem;
                z-index: 10;

                > span {
                    top: -1em;
                    color: #edcb02;
                    left: 50%;
                    transform: translate(-50%, 0);
                    transition: .5s;
                }

                > img {
                    bottom: 0;
                }
            }
        }
    }
</style>
